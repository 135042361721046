import { useState, Fragment } from "react"
import ReactMarkdown from "react-markdown"
import { useNavigate } from "react-router-dom"

import Row from "./Row"
import SectionNavigationButtons, {
  SectionNextButton,
} from "./SectionNavigationButtons"

interface ParticipationAgreementProps {
  agreement: { agreement_text: string }
}

export default function ParticipationAgreement({
  agreement,
}: ParticipationAgreementProps) {
  const navigate = useNavigate()
  const [nextButtonVisible, setNextButtonVisible] = useState(false)
  return (
    <Fragment>
      <div id="participation-agreement-text">
        <ReactMarkdown>{agreement.agreement_text}</ReactMarkdown>
      </div>

      <div id="participantion-agreement-radios" className="clearfix">
        <div className="pull-right">
          <label htmlFor="answer-yes" className="checkbox-inline">
            <input
              type="radio"
              id="answer-yes"
              name="answer"
              value="Yes"
              onChange={(e) => setNextButtonVisible(true)}
            />
            I agree
          </label>
          <label htmlFor="answer-no" className="checkbox-inline">
            <input
              type="radio"
              id="answer-no"
              name="answer"
              value="No"
              onChange={() => setNextButtonVisible(false)}
            />
            Cancel
          </label>
        </div>
      </div>

      <Row>
        <SectionNavigationButtons>
          {nextButtonVisible && (
            <SectionNextButton
              text="Get Started"
              onClick={() => navigate(`../sections/2`)}
            />
          )}
        </SectionNavigationButtons>
      </Row>
    </Fragment>
  )
}
