import type { ImportDataRow, ParsedCSVDataRow } from "./ImportSectionContainer"
import type { Section } from '../../MainTypes'

import { useState } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"

import ErrorsModal from "../ErrorsModal"
import useErrorModal from "../../utils/useErrorModal"
import convertCurrencyToNumber from "../../utils/convertCurrencyToNumber"
import Row from "../Row"
import SectionNavigationButtons, {
  SectionNextButton,
  SectionPreviousButton,
} from "../SectionNavigationButtons"
import ImportContents from "./ImportContents"
import ImportColumnMatching from "./ImportColumnMatching"
import ImportColumnMatchingRow from "./ImportColumnMatchingRow"

export type MatchStatus = "Unmatched" | "Matched" | "Skipped"

export type MatchColumnTitle =
  | ""
  | "Position Titles"
  | "Salary Info"
  | "Incentive Info"

export interface MatchRow {
  status: MatchStatus
  columnTitle: string
  matchedColumnTitle: MatchColumnTitle
  initialRows: (string | number)[]
}

export type MatchRowOptions = Array<{
  value: "Position Titles" | "Salary Info" | "Incentive Info"
  disabled: boolean
}>

interface OutletContext {
  csvData: Array<ParsedCSVDataRow>
  setImportData: (data: Array<ImportDataRow>) => void
  section: Section
}

export default function MatchImportCSVColumns() {
  const { section, csvData, setImportData }: OutletContext = useOutletContext()
  const { errorsState, setErrorsState, resetErrorsState } = useErrorModal()
  const navigate = useNavigate()

  const hasIncentiveData = section.survey_items[0].itemable.has_incentive_data
  const hasWageData = section.survey_items[0].itemable.has_wage_data
  const columnHeaders = Object.keys(csvData[0])

  const [matchingRows, setMatchRows] = useState<Array<MatchRow>>(
    Array(columnHeaders.length)
      .fill({})
      .map((_, idx) => ({
        status: "Unmatched",
        columnTitle: columnHeaders[idx],
        matchedColumnTitle: "",
        initialRows: csvData.map((row) => row[columnHeaders[idx]]).slice(0, 3),
      }))
  )

  const setMatchRow = (idx: number) => (newRow: MatchRow) => {
    setMatchRows([
      ...matchingRows.slice(0, idx),
      newRow,
      ...matchingRows.slice(idx + 1),
    ])
  }

  const positionRowMatch = matchingRows.find(
    ({ status, matchedColumnTitle }) => {
      return status === "Matched" && matchedColumnTitle === "Position Titles"
    }
  )
  const salaryRowMatch = matchingRows.find(({ status, matchedColumnTitle }) => {
    return status === "Matched" && matchedColumnTitle === "Salary Info"
  })
  const incentiveRowMatches = matchingRows.filter(
    ({ status, matchedColumnTitle }) => {
      return status === "Matched" && matchedColumnTitle === "Incentive Info"
    }
  )

  const buildImportData = () => {
    if (matchingRows.find(({ status }) => status === "Unmatched")) {
      setErrorsState({
        errors: ["Must match or skip all rows"],
        showErrors: true,
      })
      return
    }

    const matchedPositionColumnTitle = positionRowMatch?.columnTitle
    const matchedSalaryColumnTitle = salaryRowMatch?.columnTitle
    const matchedIncentiveColumnsTitles = incentiveRowMatches.map((row) => row.columnTitle)
    const incentiveInfoMatched = matchedIncentiveColumnsTitles.length > 0

    if (!matchedPositionColumnTitle) {
      setErrorsState({
        errors: ["Must match position titles column"],
        showErrors: true,
      })
      return
    }

    if (!matchedSalaryColumnTitle) {
      setErrorsState({
        errors: ["Must match salary info column"],
        showErrors: true,
      })
      return
    }

    setImportData(
      csvData.map((csvRow) => {
        const salaryInfoData = csvRow[matchedSalaryColumnTitle]
        const positionData = csvRow[matchedPositionColumnTitle]
        return {
          positionTitle: typeof positionData === "string" ? positionData : "",
          incentiveInfoMatched, 
          salaryInfo:
            typeof salaryInfoData === "number"
              ? salaryInfoData
              : convertCurrencyToNumber(salaryInfoData),
          incentiveInfo:
            incentiveInfoMatched
              ? matchedIncentiveColumnsTitles
                  .filter((attr) => csvRow[attr])
                  .map((attr) => {
                    const incentiveData = csvRow[attr]
                    return typeof incentiveData === "number"
                      ? incentiveData
                      : convertCurrencyToNumber(incentiveData)
                  })
              : []
        }
      })
    )

    navigate("../match-benchmark-positions")
  }

  const rowMatchOptions: MatchRowOptions = [
    { value: "Position Titles", disabled: Boolean(positionRowMatch) },
    hasWageData && { value: "Salary Info", disabled: Boolean(salaryRowMatch) },
    hasIncentiveData && { value: "Incentive Info", disabled: false },
  ].filter(Boolean)

  return (
    <Row>
      <ErrorsModal
        messages={errorsState.errors}
        isOpen={errorsState.showErrors}
        closeModal={resetErrorsState}
      />
      <ImportContents
        title="Great! Let's label the employee info in your columns"
        subtitle="Protip: Position and Salary Data columns must be matched before moving on."
      >
        <ImportColumnMatching>
          {matchingRows.map((row, idx) => (
            <ImportColumnMatchingRow
              key={idx}
              row={row}
              setMatchRow={setMatchRow(idx)}
              rowMatchOptions={rowMatchOptions}
            />
          ))}
        </ImportColumnMatching>
      </ImportContents>
      <SectionNavigationButtons>
        <SectionNextButton onClick={buildImportData} />
        <SectionPreviousButton onClick={() => navigate(`../csv-upload`)} />
      </SectionNavigationButtons>
    </Row>
  )
}
