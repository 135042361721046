import { useState } from "react"
import type { Dispatch, InputHTMLAttributes, SelectHTMLAttributes } from "react"
import classNames from "classnames"

export function FormGroup({
  children,
  isActive,
}: {
  children: React.ReactNode
  isActive: boolean
}) {
  const formGroupClasses = classNames({
    "form-group": true,
    active: isActive,
  })
  return <div className={formGroupClasses}>{children} </div>
}

interface LabelProps {
  children: React.ReactNode
  htmlFor: string
  mandatory?: boolean
}

export function Label({ children, htmlFor, mandatory }: LabelProps) {
  return (
    <label htmlFor={htmlFor}>
      {mandatory && (
        <span id="mandatory" style={{ color: "#e4252a" }}>
          *{" "}
        </span>
      )}
      {children}
    </label>
  )
}

interface InputExtraProps {
  mandatory?: boolean
  label: string
  id: string
  instructions?: string
}
export function Input({
  type = "text",
  autoFocus,
  placeholder,
  onChange,
  label,
  // These 3 props can usually be build the same with buildFieldProps util
  id,
  name,
  value,
  mandatory,
  instructions,
}: InputHTMLAttributes<HTMLInputElement> & InputExtraProps) {
  const [isActive, setIsActive] = useState(false)
  return (
    <FormGroup isActive={isActive}>
      <Label htmlFor={id} mandatory={mandatory}>
        {label}
      </Label>
      {instructions && <span className="instructions">{instructions}</span>}
      <input
        autoFocus={autoFocus}
        onFocus={() => setIsActive(true)}
        onBlur={() => setIsActive(false)}
        type={type}
        className={"input-lg form-control"}
        placeholder={placeholder}
        onChange={onChange}
        id={id}
        name={name}
        value={value}
      />
    </FormGroup>
  )
}

interface SelectProps {
  label?: string
  id: string
  mandatory?: boolean
  instructions?: string
}

export function Select({
  id,
  disabled,
  onChange,
  className,
  defaultValue,
  value,
  label,
  mandatory,
  instructions,
  children,
}: SelectHTMLAttributes<HTMLSelectElement> & SelectProps) {
  const [isActive, setIsActive] = useState(false)
  return (
    <FormGroup isActive={isActive}>
      {label && (
        <Label htmlFor={id} mandatory={mandatory}>
          {label}
        </Label>
      )}
      {instructions && <span className="instructions">{instructions}</span>}
      <select
        id={id}
        onFocus={() => setIsActive(true)}
        onBlur={() => setIsActive(false)}
        className={`input-lg form-control ${className}`}
        disabled={disabled}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
      >
        {children}
      </select>
    </FormGroup>
  )
}

interface RadioInputOption {
  value: string
  label: string
  id: string
}

interface RadioInputProps {
  label: string
  mandatory?: boolean
  instructions?: string
  options: RadioInputOption[]
  name: string
}
export function RadioInput({
  name,
  onChange,
  value,
  label,
  mandatory,
  instructions,
  options,
  children,
}: InputHTMLAttributes<HTMLInputElement> & RadioInputProps) {
  const [isActive, setIsActive] = useState(false)
  return (
    <FormGroup isActive={isActive}>
      {label && (
        <Label htmlFor={""} mandatory={mandatory}>
          {label}
        </Label>
      )}
      {instructions && <span className="instructions">{instructions}</span>}
      <div className="radio">
        {options.map(({ value: optionValue, id, label }) => (
          <Label key={id} htmlFor={id}>
            <input
              type="radio"
              onFocus={() => setIsActive(true)}
              onBlur={() => setIsActive(false)}
              checked={value === optionValue}
              name={name}
              id={id}
              value={optionValue}
              onChange={onChange}
            />
            {label}
          </Label>
        ))}
      </div>
    </FormGroup>
  )
}

// createBuildFieldProps takes state and dispatch from a useReducer
// it returns to you a function `buildFieldProps` that can take a string
// key name that is a property of the state object passed in.
// `buidlFieldProps` returns the most common set of form input props
// needed that are generally the same name and repeated throughout a form
// it also gives back on onChange with dispatch bound to it that updates
// state for the give state key based on the inputs name property.
//
// This is all completely Type Safe! You could not accidentally pass
// a non-property from the state object to buildFieldProps!
//
// @example:
// const [state, dispatch] = useReducer(reducer, { firstName: '' })
// const buildFieldProps = createBuildFieldProps(state, dispatch
// <input {...buildFieldProps('firstName')
export function createBuildFieldProps<T, N>(state: T, dispatch: Dispatch<N>) {
  return function buildFieldProps(name: keyof T) {
    return {
      name,
      id: name,
      value: state[name],
    }
  }
}
