import * as React from "react"

interface ColumnProps {
  screenSize?: string
  width: string
  widthOffset?: string
  textAlign?: "center"
  children: any
  className?: string
}

const Column = ({
  screenSize = "md",
  width,
  widthOffset,
  textAlign,
  children,
  className,
}: ColumnProps) => {
  const baseClass = `col-${screenSize}-${width}`
  const textAlignClass = textAlign ? `text-${textAlign}` : ""
  const widthOffsetClass = widthOffset ? `offset-${widthOffset}` : ""
  const classes = className || ""
  const classList = `${baseClass} ${classes} ${textAlignClass} ${widthOffsetClass}`

  return <div className={classList}>{children}</div>
}

export default Column
