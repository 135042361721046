import type { ReactNode } from "react"
import Column from "../Column"

interface WizardContentsProps {
  title: string
  subtitle: string
  children: ReactNode
}

export default function WizardContents({
  title,
  subtitle,
  children,
}: WizardContentsProps) {
  return (
    <section className="wizard-body">
      <section className="wizard-contents">
        <div className="container-fluid">
          <Column textAlign="center" width="12">
            <section className="wizard-title-section">
              <div id="section-title">
                <h2 data-cy="wizard-page-title" className="row-section-title">
                  {title}
                </h2>
              </div>
              <h3 data-cy="wizard-page-subtitle" className="color-slate">
                {subtitle}
              </h3>
            </section>
          </Column>
        </div>

        <div style={{ marginTop: "40px" }} className="wizard-contents-inner">
          {children}
        </div>
      </section>
    </section>
  )
}
