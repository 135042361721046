import type { Institution, Participant, SurveyItem } from "../MainTypes"
import type { States, Counties, CountiesForState } from "../config/statesList"

import { Fragment, useReducer } from "react"
import { useNavigate } from "react-router-dom"

import { baseUrl, defaultHeaders } from "../config/fetch"
import ErrorsModal from "./ErrorsModal"
import useErrorsModal from "../utils/useErrorModal"
import logError from "../utils/logError"
import Row from "./Row"
import { Input, Select, createBuildFieldProps } from "./Forms"
import statesList from "../config/statesList"
import SectionNavigationButtons, {
  SectionNextButton,
  SectionPreviousButton,
} from "./SectionNavigationButtons"

type Industry = "Bank" | "Credit Union"

interface InstitutionFormState {
  title: string
  assetsInMillions: string
  city: string
  state: States
  county: Counties
  industry: Industry
}

type InstitutionFormAction =
  | { type: "title"; value: string }
  | { type: "assetsInMillions"; value: string }
  | { type: "city"; value: string }
  | { type: "state"; value: States }
  | { type: "county"; value: Counties }
  | { type: "industry"; value: Industry }

function institutionFormReducer(
  state: InstitutionFormState,
  action: InstitutionFormAction
): InstitutionFormState {
  switch (action.type) {
    case "title":
      return { ...state, title: action.value }
    case "assetsInMillions":
      return { ...state, assetsInMillions: action.value }
    case "city":
      return { ...state, city: action.value }
    case "state":
      return { ...state, state: action.value }
    case "county":
      return { ...state, county: action.value }
    case "industry":
      return { ...state, industry: action.value }
    default:
      return state
  }
}

interface InstitutionFormProps {
  surveyId: number
  participant: Participant
  section: {
    position: number
    survey_items: SurveyItem[]
  }
}

const states = Object.keys(statesList) as States[]

const buildInitialState = (
  institution: Institution | null,
  lockedState: States | "",
  lockedIndustry: Industry | ""
) => {
  const firstState = states[0]

  return {
    title: institution?.title ?? "",
    assetsInMillions: institution?.assets_in_millions ?? "",
    city: institution?.city ?? "",
    county: lockedState
      ? statesList[lockedState][0]
      : institution?.county ?? statesList[firstState][0],
    industry: lockedIndustry || (institution?.industry ?? "Bank"),
    state: lockedState || (institution?.state ?? firstState),
  }
}

export default function InstitutionForm({
  section,
  surveyId,
  participant,
}: InstitutionFormProps) {
  const navigate = useNavigate()
  const { errorsState, setErrorsState, resetErrorsState } = useErrorsModal()
  const lockedState: States | undefined = states.find(
    (state) => state === section.survey_items[0].itemable.state
  )
  const lockedIndustry = section.survey_items[0].itemable.industry
  const [state, dispatch] = useReducer(
    institutionFormReducer,
    buildInitialState(
      participant.institution,
      lockedState ?? "",
      lockedIndustry ?? ""
    )
  )
  const selectedState = state.state
  const buildFieldProps = createBuildFieldProps(state, dispatch)

  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const payload = {
      institution: {
        survey_id: surveyId,
        title: state.title,
        assets_in_millions: state.assetsInMillions,
        city: state.city,
        state: state.state,
        county: state.county,
        industry: state.industry,
      },
    }

    try {
      let response = null

      if (participant.institution) {
        response = await fetch(
          `${baseUrl}/institutions/${participant.institution.id}`,
          {
            method: "PATCH",
            body: JSON.stringify(payload),
            headers: defaultHeaders,
          }
        )
      } else {
        response = await fetch(
          `${baseUrl}/participants/${participant.id}/institution`,
          {
            method: "POST",
            body: JSON.stringify(payload),
            headers: defaultHeaders,
          }
        )
      }

      const data = await response.json()

      if (response.ok) {
        navigate(`../sections/${section.position + 1}`)
      } else {
        setErrorsState({ errors: data, showErrors: true })
      }
    } catch (err) {
      logError(err as Error)
    }
  }

  const counties: CountiesForState<typeof selectedState> =
    statesList[selectedState]

  return (
    <Fragment>
      <ErrorsModal
        messages={errorsState.errors}
        isOpen={errorsState.showErrors}
        closeModal={resetErrorsState}
      />
      <form id="institution-form" onSubmit={submitForm}>
        <Row>
          <Input
            autoFocus
            onChange={(e) => dispatch({ type: "title", value: e.target.value })}
            label="Your Company's Name"
            {...buildFieldProps("title")}
          />
          <Input
            type="number"
            placeholder="123456789.55"
            label="Total Assets In Real Dollars"
            onChange={(e) =>
              dispatch({ type: "assetsInMillions", value: e.target.value })
            }
            {...buildFieldProps("assetsInMillions")}
          />
          <Input
            label="Headquarters City"
            onChange={(e) => dispatch({ type: "city", value: e.target.value })}
            {...buildFieldProps("city")}
          />

          <Select
            id="institution-state"
            label="State"
            disabled={Boolean(lockedState)}
            onChange={(e) =>
              dispatch({ type: "state", value: e.target.value as States })
            }
            value={selectedState}
          >
            {states.map((state) => (
              <option key={state}>{state}</option>
            ))}
          </Select>

          <Select
            id="county"
            label="County"
            onChange={(e) =>
              dispatch({ type: "county", value: e.target.value as Counties })
            }
            value={state.county}
          >
            {counties?.map((county) => (
              <option key={county}>{county}</option>
            ))}
          </Select>

          <Select
            id="industry"
            label="Industry"
            onChange={(e) =>
              dispatch({ type: "industry", value: e.target.value as Industry })
            }
            value={state.industry}
            disabled={Boolean(lockedIndustry)}
          >
            <option>Bank</option>
            <option>Credit Union</option>
          </Select>
        </Row>

        <Row>
          <SectionNavigationButtons>
            <SectionPreviousButton
              onClick={() => navigate(`../sections/${section.position - 1}`)}
            />
            <SectionNextButton />
          </SectionNavigationButtons>
        </Row>
      </form>
    </Fragment>
  )
}
