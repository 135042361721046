import { useEffect } from "react"
import useLocalStorage from "../utils/useLocalStorage"
import Shepherd from "shepherd.js"

export default function useLoadParticipantTour() {
  const { item: tourComplete, setItem: setTourComplete } = useLocalStorage(
    "participantFormTour"
  )

  useEffect(() => {
    if (!tourComplete) {
      const tour = new Shepherd.Tour({
        useModalOverlay: true,
      })

      tour.addStep({
        id: "using-tab",
        title: "This is your first question!",
        text: "When you're done, press the 'TAB' or 'ENTER' key to move on to the next question.",
        attachTo: {
          element: "#firstName",
          on: "top",
        },
        buttons: [
          {
            text: "Next",
            action: tour.next,
          },
        ],
      })

      tour.addStep({
        id: "hitting-next",
        text: "When you're done answering all of the question on the page, click 'Next' to move on.",
        scrollTo: true,
        attachTo: {
          element: "#next",
          on: "top",
        },
        buttons: [
          {
            text: "Got it. Thanks!",
            action: () => {
              setTourComplete(true)
              tour.complete()
            },
          },
        ],
      })
      tour.start()
    }
    // eslint-disable-next-line
  }, [tourComplete])
}
