import type { Dispatch, SetStateAction } from "react"
import type { Survey, Participant } from "./MainTypes"
import { Outlet } from "react-router-dom"

import Column from "./components/Column"
import CookieAndPrivacyPolicy from "./components/CookieAndPrivacyPolicy"
import { SurveyTitle } from "./components/SurveyTitle"
import { SurveyCredit } from "./components/SurveyCredit"

interface AppProps {
  survey: Survey
  setParticipant: Dispatch<SetStateAction<Participant | null>>
  participant: Participant | null
}

export default function AppLayout({
  survey,
  setParticipant,
  participant,
}: AppProps) {
  return (
    <div>
      <header>
        <div className="container">
          <div className="header-contents">
            <span className="logo">
              <img
                alt="BalancedComp"
                src={`${process.env.PUBLIC_URL}/logo-bc.svg`}
              />
            </span>
            <span className="site-title">by BalancedComp</span>

            <div className="linkgroup">
              <a
                rel="noreferrer"
                target="_blank"
                href={survey.pdf_preview_link}
              >
                Survey Preview <i className="fa fa-download" />
              </a>
              <a
                id="beacon-support-icon-trigger"
                target="_blank"
                rel="noreferrer"
                href={`mailto:support@balancedcomp.com?subject=${survey.title}%20Support`}
              >
                Contact Support
              </a>
            </div>
          </div>
        </div>
      </header>

      <div id="main-body" className="container">
        <Outlet context={{ survey, setParticipant, participant }} />
      </div>

      <div className="container">
        <footer>
          <Column screenSize="md" width="4">
            <SurveyTitle title={survey.title} />
          </Column>
          <Column screenSize="md" width="4">
            <CookieAndPrivacyPolicy />
          </Column>
          <Column screenSize="md" width="4">
            <SurveyCredit />
          </Column>
        </footer>
      </div>
    </div>
  )
}
