import { useLocation, useParams, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import AppLayout from "./AppLayout"
import { baseUrl } from "./config/fetch"
import logError from "./utils/logError"
import type { Survey, Participant } from "./MainTypes"

export default function AppContainer() {
  const { surveyId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const [survey, setSurvey] = useState<Survey | null>(null)
  const [participant, setParticipant] = useState<Participant | null>(null)

  useEffect(() => {
    fetch(`${baseUrl}/surveys/${surveyId}`)
      .then((res) => res.json())
      .then(setSurvey)
      .catch(logError)
    if (survey && survey.state === "building") {
      navigate("pending_survey")
    } else if (survey && survey.state === "closed") {
      navigate("closed_survey")
    } else {
      // On First Load of open survey take to first section if not coming back in
      if (!location.pathname.includes("jwt")) {
        navigate("./sections/1")
      }
    }
    // eslint-disable-next-line
  }, [baseUrl, survey?.state, surveyId])

  if (!survey) return null

  return (
    <AppLayout
      survey={survey}
      setParticipant={setParticipant}
      participant={participant}
    />
  )
}
