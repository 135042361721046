import { useEffect } from "react"
import useLocalStorage from "../utils/useLocalStorage"
import Shepherd from "shepherd.js"

export default function useLoadParticipantTour() {
  const { item: tourComplete, setItem: setTourComplete } = useLocalStorage(
    "importPositionMatchingTour"
  )

  useEffect(() => {
    if (!tourComplete) {
      const tour = new Shepherd.Tour({
        useModalOverlay: true,
      })

      tour.addStep({
        id: "match-state",
        title: "We're almost done!",
        text: "We just need to match the position titles you just uploaded to the benchmarks in our survey",
        classes: "example-step-extra-class",
        buttons: [
          {
            text: "SHOW ME HOW!",
            action: tour.next,
          },
        ],
      })

      tour.addStep({
        id: "your-positions",
        title: "Your positions are here on the left",
        text: "These are from the CSV you just uploaded. The number beside each position is the amount of times that position was included in your CSV file.",
        scrollTo: true,
        attachTo: {
          element: ".position-title-text",
          on: "top",
        },
        buttons: [
          {
            text: "NEXT",
            action: tour.next,
          },
        ],
      })

      tour.addStep({
        id: "departments",
        title: "Department",
        text: "Instead of showing you 100 benchmark positions, we've categorized them by department. Select the one your position might fall into.",
        scrollTo: true,
        attachTo: {
          element: ".category-select",
          on: "top",
        },
        buttons: [
          {
            text: "NEXT",
            action: tour.next,
          },
        ],
      })

      tour.addStep({
        id: "positions",
        title: "Position",
        text: "Once your've chosen a department, select the position title that most matched yours.",
        scrollTo: true,
        attachTo: {
          element: ".benchmark-select",
          on: "top",
        },
        buttons: [
          {
            text: "NEXT",
            action: tour.next,
          },
        ],
      })

      tour.addStep({
        id: "find-match",
        title: "Can't find a match?",
        text: "You can always select a different department or skip matching a position. You can always edit your selections/skips.",
        scrollTo: true,
        attachTo: {
          element: ".skip-matching",
          on: "left",
        },
        buttons: [
          {
            text: "GOT IT!",
            action: () => {
              setTourComplete(true)
              tour.complete()
            },
          },
        ],
      })
      tour.start()
    }
    // eslint-disable-next-line
  }, [tourComplete])
}
