import type { Dispatch, SetStateAction } from "react"
import type { Participant } from "../MainTypes"

import { useState, useEffect } from "react"
import jwt_decode from "jwt-decode"
import { useOutletContext, useNavigate, useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import logError from "../utils/logError"
import ErrorModal from '../components/ErrorsModal'

import { baseUrl } from "../config/fetch"

export default function ParseParticipantJWT() {
  const {
    setParticipant,
  }: { setParticipant: Dispatch<SetStateAction<Participant>> } =
    useOutletContext()
  const [errorModal, setErrorModal]: [ errorModal: boolean, setErrorModal: Function] = useState(false)
  const navigate = useNavigate()
  const { token } = useParams()
  let id: number|null = null

  invariant(token, "No jwt token provided by route")

  try {
    const {id: jwtId}: {id:number} = jwt_decode(token)
    id = jwtId
  } catch (error) {
    setErrorModal(true)
  }

  useEffect(() => {
    fetch(`${baseUrl}/participants/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setParticipant(data)
        navigate("../sections/2")
      })
      .catch(logError)
    // @eslint-disable-next-line
  }, [id, setParticipant, navigate])

  return errorModal
    ? (
      <ErrorModal
        messages={["Bad JWT Token"]}
        closeModal={() => setErrorModal(false)}
        isOpen={errorModal}
      />
    )
    : null
}
