export const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
}

const getBaseUrl = () => {
  if (process.env.REACT_APP_BASE_URL) {
    return process.env.REACT_APP_BASE_URL
  } else {
    return "http://localhost:5001/api/v2"
  }
}
export const baseUrl = getBaseUrl()
