import "shepherd.js/dist/css/shepherd.css"
import "./styles/theme.scss"

import * as React from "react"
import * as ReactDOM from "react-dom"

import AppRoutes from "./AppRoutes"
import ScrollToTop from "./components/ScrollToTop"
import { BrowserRouter } from "react-router-dom"

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <AppRoutes />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
)
