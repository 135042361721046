import { Routes, Route } from "react-router-dom"
import AppContainer from "./AppContainer"
import SurveySummary from "./components/SurveySummary"
import SurveySection from "./components/SurveySection"
import MatchImportCSVColumns from "./components/ImportSection/MatchImportCSVColumns"
import ImportFileUpload from "./components/ImportSection/ImportFileUpload"
import MatchBenchmarkPositions from "./components/ImportSection/MatchBenchmarkPositions"
import ImportSectionContainer from "./components/ImportSection/ImportSectionContainer"
import ParseParticipantJWT from "./components/ParseParticipantJWT"

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="surveys/:surveyId" element={<AppContainer />}>
        <Route path="jwt/:token" element={<ParseParticipantJWT />} />
        <Route path="survey-summary" element={<SurveySummary />} />
        <Route path="sections/:section_id" element={<SurveySection />}>
          <Route path="import" element={<ImportSectionContainer />}>
            <Route path="csv-upload" element={<ImportFileUpload />} />
            <Route
              path="match-csv-columns"
              element={<MatchImportCSVColumns />}
            />
            <Route
              path="match-benchmark-positions"
              element={<MatchBenchmarkPositions />}
            />
          </Route>
        </Route>

        <Route path="closed_survey" element={<h1 style={{marginTop: "40px"}}>Survey Closed</h1>} />
        <Route path="pending_survey" element={<h1 style={{marginTop: "40px"}}>Survey Not Open Yet</h1>} />
      </Route>
    </Routes>
  )
}
