import LogRocket from 'logrocket'

export default function configIntegrations(participant) {
  if (
    window.Cypress ||
    ["development", "staging"].includes(process.env.NODE_ENV)
  ) {
    return
  }

  LogRocket.init(`9vb4l2/lewis-and-clark`)

  const userName = `${participant.first_name} ${participant.last_name}`
  const userEmail = `${participant.email}`
  const userID = `${participant.id}`

  LogRocket.identify(userID, {
    name: userName,
    email: userEmail
  })

}