import { useState } from "react"

export interface UseErrorsState {
  errors: null | string[]
  showErrors: boolean
}

const initialState = { errors: null, showErrors: false }

export default function useErrorModal() {
  const [errorsState, setErrorsState] = useState<UseErrorsState>(initialState)
  const resetErrorsState = () => setErrorsState(initialState)

  return { errorsState, setErrorsState, resetErrorsState }
}
