interface SectionTitleProps {
  surveyTitle: string
  sectionTitle: string
  showSurveyTitle: boolean
  showSectionTitle: boolean
}

export default function SectionTitle({
  surveyTitle,
  sectionTitle,
  showSurveyTitle,
  showSectionTitle,
}: SectionTitleProps) {
  return (
    <div style={{ marginBottom: "40px", display: 'inline-block' }} className="row-section-title">
      {showSurveyTitle && (
        <div id="main-title">
          <h1>{surveyTitle}</h1>
        </div>
      )}
      {showSectionTitle && (
        <div id="section-title" className="pull-left">
          <h2>{sectionTitle}</h2>
        </div>
      )}
    </div>
  )
}
