import type { ReactNode } from "react"
import Row from "../Row"
import Column from "../Column"

function WizardColumnMatching({ children }: { children: ReactNode }) {
  return (
    <div className="container-fluid">
      <Row>
        <section id="section-1" className="wizard-accordion">
          <div className="wizard-accordion-header">
            <Row>
              <Column width="9">
                <Row>
                  <Column width="8">CSV Header</Column>
                  <Column width="4">BalancedComp Match</Column>
                </Row>
              </Column>

              <Column width="3">
                <Row>
                  <Column width="8">Status</Column>
                  <Column width="4">Actions</Column>
                </Row>
              </Column>
            </Row>
          </div>

          {children}
        </section>
      </Row>
    </div>
  )
}

export default WizardColumnMatching
