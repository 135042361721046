import { useEffect } from "react"
import useLocalStorage from "../utils/useLocalStorage"
import Shepherd from "shepherd.js"

export default function useLoadParticipantTour() {
  const { item: tourComplete, setItem: setTourComplete } = useLocalStorage(
    "importUploadTour"
  )

  useEffect(() => {
    if (!tourComplete) {
      const tour = new Shepherd.Tour({
        useModalOverlay: true,
      })

      tour.addStep({
        id: "upload-file",
        title: "What's a CSV File",
        text: "After you have an excel file with your employee data from your Payroll or HRIS system, you should be able to 'Save As' a CSV in Excel. If you need help, please contact us, or <a href='https://support.office.com/en-us/article/Import-or-export-text-txt-or-csv-files-5250ac4c-663c-47ce-937b-339e391393ba' target='_blank'>check out this article</a>",
        buttons: [
          {
            text: "GOT IT! THANKS!",
            action: () => {
              setTourComplete(true)
              tour.complete()
            }
          },
        ],
      })

      tour.start()
    }
    // eslint-disable-next-line
  }, [tourComplete])
}
