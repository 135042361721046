import * as React from "react"

interface RowProps {
  children: React.ReactNode
  style?: React.CSSProperties | undefined
}

const Row = ({ style, children }: RowProps) => {
  return (
    <div style={style} className="row">
      {children}
    </div>
  )
}

export default Row
