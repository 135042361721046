export default function CookieAndPrivacyPolicy() {
  return (
    <div id="cookie-and-privacy-policy">
      <a
        rel="noreferrer"
        target="_blank"
        href="https://app.termly.io/document/cookie-policy/cd0e0938-0072-419e-b7c2-7d508044680a"
      >
        Cookie Policy
      </a>
      <a
        rel="noreferrer"
        target="_blank"
        href="https://app.termly.io/document/privacy-policy/e7019c60-7ddb-45b2-a6b1-472e0cf95e36"
        className="last"
      >
        Privacy Policy
      </a>
    </div>
  )
}
