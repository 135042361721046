import type { Participant, Section } from "../MainTypes"

import { useState } from "react"
import { useNavigate } from "react-router-dom"
import ErrorsModal from "./ErrorsModal"
import useErrorModal from "../utils/useErrorModal"
import Row from "./Row"
import { Input, RadioInput, Select } from "./Forms"
import updateArrayAtIndex from "../utils/updateArrayAtIndex"
import logError from "../utils/logError"
import { defaultHeaders, baseUrl } from "../config/fetch"
import SectionNavigationButtons, {
  SectionNextButton,
  SectionPreviousButton,
} from "./SectionNavigationButtons"

interface QuestionsSectionProps {
  section: Section
  participant: Participant
  isLastSection: boolean
}

interface Answer {
  question_id: number
  answer: string
}

export default function QuestionsSection({
  section,
  participant,
  isLastSection,
}: QuestionsSectionProps) {
  const navigate = useNavigate()
  const { errorsState, setErrorsState, resetErrorsState } = useErrorModal()
  const surveyItems = section.survey_items.filter(
    (item) => item.itemable_type === "Question"
  )

  const [state, setState] = useState<Answer[]>(
    surveyItems.map((surveyItem) => ({
      question_id: surveyItem.itemable.id,
      answer:
        participant.answers.find(
          (answer) => answer.question_id === surveyItem.itemable.id
        )?.answer || "",
    }))
  )

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const unansweredQuestionIds = state
      .filter(({ answer }) => answer.trim() === "")
      .map(({ question_id }) => question_id)

    const anyMandatoryUnanswered = surveyItems.filter((item) => {
      const isMandatory = item.itemable.mandatory
      return isMandatory && unansweredQuestionIds.includes(item.itemable.id)
    })

    if (anyMandatoryUnanswered.length > 0) {
      setErrorsState({
        errors: anyMandatoryUnanswered.map(
          (item) => `This question must be answered: ${item.itemable.question}`
        ),
        showErrors: true,
      })
      return
    }

    const payloads: { answer: Answer }[] = state.filter(answer => answer.answer.trim() !== '')
      .map((answer) => ({
        answer: {
          answer: answer.answer,
          question_id: answer.question_id,
        },
      }))

    const answerRequests = payloads.map((payload) => {
      const existingAnswer = participant.answers.find(
        (answer) => answer.question_id === payload.answer.question_id
      )
      if (existingAnswer) {
        return fetch(`${baseUrl}/answers/${existingAnswer.id}`, {
          method: "PATCH",
          body: JSON.stringify(payload),
          headers: defaultHeaders,
        })
      } else {
        return fetch(`${baseUrl}/participants/${participant.id}/answers`, {
          method: "POST",
          body: JSON.stringify(payload),
          headers: defaultHeaders,
        })
      }
    })

    await Promise.all(answerRequests)
      .then((responses) => {
        const failedRequest = responses.find((r) => !r.ok)
        if (failedRequest) {
          Promise.reject(failedRequest)
        } else {
          Promise.resolve(responses)
          if (isLastSection) {
            navigate(`../survey-summary`)
          } else {
            navigate(`../sections/${section.position + 1}`)
          }
        }
      })
      .catch(logError)
  }

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    idx: number
  ) => {
    setState(
      updateArrayAtIndex(state, idx, { ...state[idx], answer: e.target.value })
    )
  }

  return (
    <>
      <ErrorsModal
        messages={errorsState.errors}
        isOpen={errorsState.showErrors}
        closeModal={resetErrorsState}
      />
      <form id={`section-${section.position}`} onSubmit={onSubmit}>
        <Row>
          {surveyItems.map((surveyItem, idx) => {
            const question = surveyItem.itemable

            if (surveyItem.component_type === "RadioInput") {
              return (
                <RadioInput
                  key={idx}
                  label={question.question}
                  instructions={question.instructions}
                  name={`question-${question.id}`}
                  onChange={(e) => onChange(e, idx)}
                  value={state[idx].answer}
                  options={
                    surveyItem.list_options
                      ? surveyItem.list_options.map(({ option }) => ({
                          value: option,
                          label: option,
                          id: `question_${idx + 1}_${option}`,
                        }))
                      : []
                  }
                />
              )
            } else if (surveyItem.component_type === "DropdownInput") {
              return (
                <Select
                  key={idx}
                  label={question.question}
                  instructions={question.instructions}
                  id={`question-${question.id}`}
                  name={`question-${question.id}`}
                  onChange={(e) => onChange(e, idx)}
                  value={state[idx].answer}
                >
                  <option value="" disabled hidden>
                    Choose an answer...
                  </option>
                  {surveyItem.list_options?.map(({ option }) => (
                    <option>{option}</option>
                  ))}
                </Select>
              )
            } else if (surveyItem.component_type === "StringFieldInput") {
              return (
                <Input
                  key={idx}
                  label={question.question}
                  instructions={question.instructions}
                  id={`question-${question.id}`}
                  name={`question-${question.id}`}
                  value={state[idx].answer}
                  onChange={(e) => onChange(e, idx)}
                />
              )
            } else if (surveyItem.component_type === "NumberInput") {
              return (
                <Input
                  key={idx}
                  type="number"
                  label={question.question}
                  instructions={question.instructions}
                  id={`question-${question.id}`}
                  name={`question-${question.id}`}
                  value={state[idx].answer}
                  onChange={(e) => onChange(e, idx)}
                />
              )
            } else {
              return null
            }
          })}
        </Row>

        <SectionNavigationButtons>
          <SectionNextButton />
          <SectionPreviousButton
            onClick={() => navigate(`../sections/${section.position - 1}`)}
          />
        </SectionNavigationButtons>
      </form>
    </>
  )
}
