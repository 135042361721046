import { useState } from "react"

import type { MatchColumnTitle, MatchRow } from "./MatchImportCSVColumns"
import Row from "../Row"
import Column from "../Column"
import ImportColumnMatchingList from "./ImportColumnMatchingList"

interface ImportColumnMatchingRowProps {
  children?: React.ReactNode
  onSelectChange?: () => void
  selectedValue?: string
  setMatchRow: (newRow: MatchRow) => void
  row: MatchRow
  rowMatchOptions: Array<{
    value: "Position Titles" | "Salary Info" | "Incentive Info"
    disabled: boolean
  }>
}

function ImportColumnMatchingRow({
  onSelectChange,
  selectedValue,
  row,
  setMatchRow,
  rowMatchOptions,
}: ImportColumnMatchingRowProps) {
  const { columnTitle, status, initialRows } = row
  const [showDataRows, setShowDataRows] = useState(true)

  // When they click skip we want to do a few things
  // 1. hide the 3 data rows if they're showing
  // 2. if the row you're skipping is already matched, unmatched it and set status to skipped in actual matching object
  // 3. No matter what the status is, update the matchingCSVRows object that represetnts that matching UI state to skipped for the given key
  const onSkip = () => {
    setShowDataRows(false)
    setMatchRow({ ...row, status: "Skipped" })
  }

  const onSkippedChange = () => {
    setShowDataRows(true)
    setMatchRow({ ...row, status: "Unmatched" })
  }

  return (
    <div className="row" data-testid={`matching-row ${columnTitle}`}>
      <div
        className={`row wizard-accordion-row status-${status.toLowerCase()}`}
      >
        <Column width="9" className="wizard-accordion-matching">
          <Row>
            <Column width="8">
              <h1
                style={{ cursor: "pointer" }}
                onClick={() => setShowDataRows(!showDataRows)}
              >
                {columnTitle}
              </h1>
              <span className="arrow-right" />
            </Column>

            <Column width="4">
              <select
                className="select"
                value={row.matchedColumnTitle}
                onChange={(e) => {
                  setMatchRow({
                    ...row,
                    matchedColumnTitle: e.target.value as MatchColumnTitle,
                    status: "Matched",
                  })
                  setShowDataRows(false)
                }}
                id="selectColumnMatching"
              >
                <option value="" disabled hidden>
                  Select...
                </option>
                {rowMatchOptions.map(({ value, disabled }) => (
                  <option key={value} disabled={disabled}>
                    {value}
                  </option>
                ))}
              </select>
            </Column>
          </Row>
        </Column>

        <Column width="3" className="wizard-accordion-actions">
          <Row>
            <Column width="8">
              <strong data-testid="status" className="status">
                {status}
              </strong>
            </Column>
            <Column width="4">
              <button
                data-testid="skip-button"
                className="secondary"
                onClick={status === "Skipped" ? onSkippedChange : onSkip}
              >
                {status === "Skipped" ? "Edit" : "Skip"}
              </button>
            </Column>
          </Row>
        </Column>
      </div>

      {showDataRows && <ImportColumnMatchingList options={initialRows} />}
    </div>
  )
}

export default ImportColumnMatchingRow
