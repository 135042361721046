import { useEffect } from "react"
import { createPortal } from "react-dom"
import invariant from "tiny-invariant"

interface ModalProps {
  header: string | null
  closeModal: () => void
  isOpen?: boolean
  children: any
}
export default function Modal({
  header,
  children,
  closeModal,
  isOpen,
}: ModalProps) {
  let el: Element = document.createElement("div")

  useEffect(() => {
    const modalRoot = document.querySelector(`.modal-overlay`)
    invariant(modalRoot, "No node found with selector .modal-overlay")

    if (isOpen) {
      el.setAttribute("class", "modal-container")
      modalRoot.appendChild(el)
    }

    return () => {
      if (isOpen) {
        modalRoot.removeChild(el)
      }
    }
  }, [isOpen, el])

  useEffect(() => {
    const bodyEl = document.querySelector("body")
    const modalEl: HTMLElement | null = document.querySelector('.modal')
    invariant(bodyEl, "No node found with selector body")
    invariant(modalEl, `No node found with selector .modal`)

    // Hide/show .modal element on isOpen
    modalEl.style.display = isOpen ? "flex" : "none"

    // add/remove modal-open class from body element on isOpen
    if (isOpen) {
      bodyEl.classList.add("modal-open")
    } else {
      bodyEl.classList.remove("modal-open")
    }

    return () => {
      if (modalEl) {
        modalEl.style.display = "none"
      }
    }
  }, [isOpen])

  if (!isOpen) return null

  return createPortal(
    <>
      <div className="m-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <h1>{header}</h1>
            </div>
          </div>
        </div>

        <div className="m-close" onClick={closeModal}>
          <i className="fa fa-times" aria-hidden="true"></i>
        </div>
      </div>

      <div className="m-body">
        <div className="container-fluid">
          <div className="row">
            <div className="error-message col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              {children}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="m-footer">
      <div className="container-fluid">
        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right">
          <button type="submit" className="submit ">M'kay</button>
        </div>
      </div>
    </div> */}
    </>,
    el
  )
}
