import type { Participant } from '../MainTypes'
import { baseUrl, defaultHeaders } from '../config/fetch'

type ParticipantParams = Omit<
  Partial<Participant>, 
  'id' | 'institution' | 'answers' | 'wage_data_points' | 'incentive_data_points'
>

export function updateParticipant(participantId: number, participantParams: ParticipantParams) {
  fetch(`${baseUrl}/participants/${participantId}`, {
    method: "PATCH",
    body: JSON.stringify({ participant: participantParams }),
    headers: defaultHeaders
  })
}
