import Modal from './Modal'

interface ErrorsModalProps {
  messages: null | string[]
  closeModal: () => void
  isOpen?: boolean
}

export default function ErrorsModal({ messages, closeModal, isOpen }: ErrorsModalProps) {
  return (
    <Modal 
      header='Please check the form'
      closeModal={closeModal}
      isOpen={isOpen}
    >
      {messages?.map((message, idx) => (
        <p key={idx}>{message}</p>
      ))}
    </Modal>
  )
}
