interface SurveyTitleProps {
  title: string
}

export function SurveyTitle({ title }: SurveyTitleProps) {
  return (
    <div id="survey-title" className="pull-left">
      {title}
    </div>
  )
}
