import Row from "../Row"
import Column from "../Column"

export default function WizardColumnMatchingList({
  options,
}: {
  options: (string | number)[]
}) {
  const valueList = options.map((item, index) => {
    return item ? (
      <h2 data-testid="position-title" key={index}>
        {item}
      </h2>
    ) : (
      <em key={index}>
        <h2 data-testid="position-title" className="color-slate-light">
          Empty
        </h2>
      </em>
    )
  })

  return (
    <Row>
      <Column width="6">
        <div
          data-testid="wizard-accordion-list"
          className="wizard-accordion-list"
        >
          {valueList}
        </div>
      </Column>
    </Row>
  )
}
